import emailjs from "@emailjs/browser";
import { useRef } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
  const form = useRef();
  const notify = () => toast("Message sent successfully!");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_h9n7wik", // SERVICE ID
      "template_uxb6lso", // TEMPLATE ID
      form.current,
      "d43DoKmjGzL5HMJGD" // PUBLIC KEY
    );
    e.target.reset();
    notify();
  };

  return (
    <form
      className="contact-form flex"
      ref={form}
      onSubmit={(e) => {
        sendEmail(e);
      }}
    >
      <div className="flex">
        <input
          type="text"
          className="input small-input"
          name="name"
          id="name"
          placeholder="Name*"
          required
        />
        <input
          type="text"
          className="input small-input"
          name="surname"
          id="surname"
          placeholder="Surname*"
          required
        />
      </div>
      <input
        className="input"
        type="email"
        name="email"
        id="email"
        placeholder="Email*"
        required
      />
      <input
        className="input"
        type="number"
        name="phoneNumber"
        id="phoneNumber"
        placeholder="Phone number*"
        required
      />
      <textarea
        className="input"
        name="message"
        id="message"
        placeholder="Message*"
        required
      ></textarea>
      <button
        className="fs-500 bold contact-button button-dark transition pointer"
        type="submit"
      >
        SEND
      </button>
      <ToastContainer
        theme="light"
        position="bottom-right"
        style={{ width: "fit-content" }}
      />
    </form>
  );
}

export default ContactForm;
