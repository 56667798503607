function ServicesDescription() {
  return (
    <div className="services-description flex" id="services">
      <div className="services-title">
        <h3 className="fs-300 ff-worksans medium">FIND OUT MORE</h3>
        <h2 className="fs-600 semi-bold">
          We build successful, lasting, profitable products
        </h2>
        <p className="fs-500 medium italic">
          Reimagining brand identities and business model approaches.
        </p>
      </div>
      <div className="services-text flex ff-worksans">
        <p>
        At the heart of our mission is the commitment to build successful, lasting, and profitable products. We blend innovation, quality, and customer-centric design to create offerings that meet evolving needs and stand the test of time.
        </p>
        <p>
        Our focus on sustainability ensures relevance and value, fostering loyalty and driving profitability. Together, we craft experiences that empower and inspire.
        </p>
      </div>
    </div>
  );
}

export default ServicesDescription;
