import Image from "../../assets/images/banner-image.jpg";

function BannerImage() {
  return (
    <div className="banner-image">
      <img src={Image} alt="FlutterSphere Banner" />
    </div>
  );
}

export default BannerImage;
