import {
  Banner,
  Newsletter,
  Services,
  Carousel,
  Talk,
  Team,
  Blogs,
  Contact,
  Footer,
} from "./components/index";

export default function App() {
  return (
    <>
      <Banner />
      <Newsletter />
      <Services />
      <Carousel />
      {/* <Talk /> */}
      <Team />
      <Blogs />
      <Contact />
      <Footer />
    </>
  );
}
