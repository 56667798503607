import BlogCard from "./blogs/BlogCard";
export default function Blogs() {
  return (
    <div className="blogs bg-gray">
      <div className="container">
        <BlogCard title='Brand Awareness' date='' description='Brand awareness is a fundamental aspect of marketing that signifies how well consumers recognize and remember a brand. It serves as the first step in the consumer journey, influencing their perception and choice of products or services'/>
        <BlogCard title='Website trafficing' date='' description='Website traffic is a critical metric in digital marketing that measures the number of visitors to a website over a specific period. Understanding and analyzing website traffic helps businesses gauge the effectiveness of their online presence and marketing strategies.'/>
        <BlogCard title='Direct sales' date='' description='We create impactful ads that drive sales growth directly by using precise methods and targeting specific audiences for maximum effectiveness. Our strategy combines data-driven insights with creative excellence to craft compelling advertisements that resonate with your target market.'/>
        <BlogCard title='Analytics' date='' description='Analytics is a tool that analyzes site traffic and user behavior, providing data on interactions such as page views, time spent, and conversion rates. By leveraging these insights, businesses can make informed decisions to optimize their strategies, enhance user experiences, and drive better outcomes. With detailed reports and actionable data, you can identify trends.'/>
      </div>
    </div>
  );
}