import Arrow from "../../assets/icons/arrow-right.png";
export default function BlogCard({date, title, description}) {
  return (
    <div className="blog-card">
      <h3 className="ff-worksans fs-300 medium clr-gray uppercase">{date}</h3>
      <h2 className="fs-500 bold">{title}</h2>
      <p className="">{description}</p>
      <div className="more-container flex">
        <p className="ff-worksans fs-300 semi-bold uppercase">Find out more</p>
        <img src={Arrow} alt="Arrow Right Icon" />
      </div>
    </div>
  );
}