import Arrow from "../../assets/icons/arrow-right.png";

function NewsletterForm() {
  return (
    <form className="flex">
      <input
        type="email"
        className="italic medium "
        placeholder="Add mail and Subscribe"
      />
      <button type="submit" className="pointer">
        <img src={Arrow} alt="Arrow Right Icon" height={10} />
      </button>
    </form>
  );
}

export default NewsletterForm;
