import Logo from "../assets/images/logo.jpg";

export default function Header() {
  return (
    <div className="header container flex">
      <img className="logo" src={Logo} alt="FlutterSphere Logo" />
      <h2 className="fs-500 bold">FlutterSphere</h2>
    </div>
  );
}
