import Logo from "../assets/images/logo.jpg";
import Facebook from "../assets/icons/facebook-white.svg";
import LinkedIn from "../assets/icons/linkedin-white.svg";
import Twitter from "../assets/icons/twitter-white.svg";

export default function Footer() {
  return (
    <div className="footer bg-black clr-white">
      <div className="footer-container container flex">
        <div className="logo flex">
          <img src={Logo} alt="FlutterSphere Logo" />
          <h2>FlutterSphere</h2>
        </div>
        <div className="footer-content">
          <div className="footer-top flex ">
            <div>
              <h3>Want to work with us?</h3>
              <h4 className="fs-300 semi-bold ff-worksans clr-gray">
                USE THIS EMAIL
              </h4>
              <h5 className="clr-gray ff-worksans">
                contact@fluttersphere.com
              </h5>
            </div>
            <div>
              <h3>Want to say hi?</h3>
              <h4 className="fs-300 semi-bold ff-worksans clr-gray">
                GENERAL INQUIRIES
              </h4>
              <h5 className="clr-gray ff-worksans">
                contact@fluttersphere.com
              </h5>
            </div>
            <div>
              <h3>Find us on social media.</h3>
              <h4 className="fs-300 semi-bold ff-worksans clr-gray">
                SOCIAL MEDIA
              </h4>
              <div className="socials-holder flex">
                <a href="https://www.facebook.com/profile.php?id=61561536142105" className="flex">
                  <img src={Facebook} alt="FaceBook Icon" />
                </a>
                <a href="https://www.linkedin.com/company/fluttersphere-social-media-marketing-agency/about/" className="flex">
                  <img
                    className="linkedin"
                    src={LinkedIn}
                    alt="LinkedIn Icon"
                  />
                </a>
                <a href="https://www.instagram.com/fluttersphere2/?hl=en" className="flex">
  <img className="twitter" src={Twitter} alt="Twitter Icon" style={{
    display: 'none'
  }} />
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{
    width: '100%',
    height: '100%',
    fill: 'currentColor'
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
  </svg>
</a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <h3>FlutterSphere location</h3>
            <div className="clr-gray ff-worksans">
              <h4 className="fs-300 semi-bold ff-worksans clr-gray">
                NEW YORK
              </h4>
              <h5>Bronx New York,</h5>
              <h5>NY 10467</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright clr-gray fs-400 ff-worksans container">
        <p>
          &copy; {new Date().getFullYear()} FutterSphere, developed by{" "}
          <a href="https://www.ardbraha.com/" target="_blank" rel="noreferrer">
            Ard Braha.
          </a>
        </p>
      </div>
    </div>
  );
}
