import Pfp from "../../assets/icons/profile.png";

function Testimonial({ name, role, children }) {
  return (
    <div className="testimonial">
      <img src={Pfp} alt="Testimonial Customer Profile" />
      <h3 className="fs-500 medium italic">{children}</h3>
      <h4 className="bold">{name}</h4>
      <h5 className="fs-300 ff-worksans medium uppercase">{role}</h5>
    </div>
  );
}

export default Testimonial;
