import ServiceCard from "./ServiceCard";
import ImageOne from "../../assets/images/service-image-one.jpg";
import ImageTwo from "../../assets/images/service-image-two.jpg";
import ImageThree from "../../assets/images/service-image-three.jpg";
import ImageFour from "../../assets/images/service-image-four.jpg";

function CardsContainer() {
  return (
    <div className="card-container flex">
      <ServiceCard title="INNOVATION" text="Lock Smith doo" image={ImageOne} />
      <ServiceCard
        title="INNOVATION"
        text="Trademark"
        image={ImageTwo}
        isEven={true}
      />
      <ServiceCard title="INNOVATION" text="Bootstraping" image={ImageThree} />
      <ServiceCard
        title="INNOVATION"
        text="Personal Guarantee"
        image={ImageFour}
        isEven={true}
      />
    </div>
  );
}

export default CardsContainer;
