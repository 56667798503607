import Header from "./Header";
import BannerContent from "./banner/BannerContent";
import BannerImage from "./banner/BannerImage";

export default function Banner() {
  return (
    <div className="banner flex">
      <Header />
      <BannerContent />
      <BannerImage />
    </div>
  );
}
