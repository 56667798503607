import CardsContainer from "./services/CardsContainer";
import ServicesDescription from "./services/ServicesDescription";

export default function Services() {
  return (
    <div className="services container flex">
      <ServicesDescription />
      <CardsContainer />
    </div>
  );
}
