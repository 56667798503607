import Slider from "react-slick";
import Testimonial from "./Testimonial";

function SliderComponent() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      <Testimonial name="James Harrison" role="Client">
      Working with Flutter-Sphere has been a game-changer for our brand.
      Their innovative marketing strategies and tailored campaigns have significantly boosted our visibility and engagement in a crowded market.
      </Testimonial>
      <Testimonial name="Michael Thompson" role="Client">
      Flutter-Sphere's expertise in digital marketing has transformed our online presence.
      Their data-driven approach and creative solutions have led to remarkable growth in our customer base and brand recognition.
      </Testimonial>
      <Testimonial name="William Parker" role="Client">
      The team at Flutter-Sphere truly understands our needs and goals.
      Their strategic insights and effective marketing tactics have provided us with measurable results and a strong competitive edge.
      </Testimonial>
    </Slider>
  );
}

export default SliderComponent;
