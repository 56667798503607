import NewsletterForm from "./newsletter/NewsletterForm";

export default function Newsletter() {
  return (
    <div className="newsletter bg-gray flex">
      <h3 className="fs-500 semi-bold">Stay in the loop at all times.</h3>
      <NewsletterForm />
    </div>
  );
}
