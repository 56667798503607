import ContactForm from "./contact/ContactForm";

export default function Contact() {
  return (
    <div className="contact container" id="contact">
      <h2 className="fs-600 bold">Contact Us!</h2>
      <ContactForm />
    </div>
  );
}
