import Arrow from "../../assets/icons/arrow-right.png";

function BannerContent() {
  return (
    <div className="banner-content flex">
      <h1 className="fs-700 bold">Interaction & Experience</h1>
      <div>
        <p className="fs-500 semi-bold italic">
          Discover a better way of redefining company goals and achieving higher
          conversion rates the natural way.
        </p>
        <a
          href="#services"
          className="bg-gray fs-300 bold ff-worksans transition"
        >
          FIND OUT MORE <img src={Arrow} alt="Arrow Right" height={10} />
        </a>
      </div>
    </div>
  );
}

export default BannerContent;
