function TeamHeader() {
  return (
    <div className="team-header">
      <h3 className="ff-worksans fs-300 semi-bold">THE TEAM</h3>
      <h2 className="fs-600 semi-bold">Meet our team of creatives</h2>
    </div>
  );
}

export default TeamHeader;
