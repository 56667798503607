

export default function TeamCard({ name, role, person}) {
  return (
    <div className={`team-card transition pointer ${person}`}>
      <div className="team-card-content flex transition">
        <h2 className="fs-500 semi-bold">{name}</h2>
        <h3 className="fs-400 italic medium">{role}</h3>
        
      </div>
    </div>
  );
}
