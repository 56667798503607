import TeamCard from "./TeamCard";


function CardsContainer() {
  return (
    <div className="team-card-container">
      <TeamCard
        name="Flutura Maloku"
        role="CEO & Founder"
        facebook="#"
        linkedin="#"
        twitter="#"
        person="person-one"
      />

      <TeamCard
     
        name="Ridvan Maloku"
        role="3D Motion Design"
        facebook="#"
        linkedin="#"
        twitter="#"
        person="person-two"
      />
      <TeamCard
      
        name="Arbenita Nishevci"
        role="Social Media Marketing"
        facebook="#"
        linkedin="#"
        twitter="#"
        person="person-three"
      />
      <TeamCard
      
        name="Elza Avdiu"
        role="Content Marketing"
        facebook="#"
        linkedin="#"
        twitter="#"
        person="person-four"
      />
      <TeamCard
      
        name="Qëndrim Nishevci"
        role="Analytics and Reporting"
        facebook="#"
        linkedin="#"
        twitter="#"
        person="person-five"
      />
      <TeamCard
        name="Amar Rexha"
        role="Web Developer"
        facebook="#"
        linkedin="#"
        twitter="#"
        person="person-six"
      />
    </div>
  );
}

export default CardsContainer;
