import Arrow from "../../assets/icons/arrow-right.png";

function TeamFooter() {
  return (
    <div className="team-footer flex">

      <a
        href="#contact"
        className="fs-500 bold contact-button pointer transition"
      >
        Contact Us <img src={Arrow} alt="Arrow Right Icon" />
      </a>
    </div>
  );
}

export default TeamFooter;
