import TeamFooter from "./team/TeamFooter";
import CardsContainer from "./team/CardsContainer";
import TeamHeader from "./team/TeamHeader";

export default function Team() {
  return (
    <div className="team container">
      <TeamHeader />
      <CardsContainer />
      <TeamFooter />
    </div>
  );
}
