import Eye from "../../assets/icons/eye.svg";

function ServiceCard({ image, title, text, isEven }) {
  return (
    <div className={`service-card transition pointer ${isEven && "even-card"}`}>
      <img
        src={image}
        alt="Inovation Service FlutterSphere"
        className="transition"
      />
      <div className="white-layer bg-white transition">
        <img src={Eye} alt="Eye Icon" />
        <h5 className="ff-worksans fs-300 medium">{title}</h5>
        <h4 className="medium fs-500">{text}</h4>
      </div>
    </div>
  );
}

export default ServiceCard;
